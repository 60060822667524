
import { base_url } from "./constants";
import axios from 'axios';




// AUTHENTICATION
export const AgencyLogin = async(username, password) =>{
    return await axios.post(`${base_url}/agency/login/`, {
        email: username.trim(),
        password: password
    });
};
export const AgencyGetProfile = async(username, uid) =>{
    return await axios.post(`${base_url}/agency/get-profile/`, {
        email: username.trim(),
        uid: uid
    });
};

export const AgencyGetSAContact = async(username, uid) =>{
    return await axios.get(`${base_url}/agency/super-admin-contact/`);
};

export const AgencySendResetPassword = async(email) =>{
    return await axios.post(`${base_url}/agency/send-reset-password/`, {
        email: email,
    });
};
export const AgencyUpdatePassword = async(password, confPassword, uid, token) =>{
    return await axios.post(`${base_url}/agency/reset-password/${uid}/${token}/`, {
        password: password,
        password2: confPassword
    });
};

export const AgencyChangePassword = async(data) =>{
    return await axios.post(`${base_url}/agency/change-password/`, data);
};



export const AdminRegisterAgency = async(data) =>{
    return await axios.post(`${base_url}/addmin/register-agency/`, data);
};


export const AgencyUploadBanner = async(data) =>{
    return await axios.post(`${base_url}/agency/upload-banner-image/`, data);
};

export const AgencySubmitDTS = async(data) =>{
    return await axios.post(`${base_url}/agency/submit-dts-lisence/`, data);
};

export const AgencySubmitOEP = async(data) =>{
    return await axios.post(`${base_url}/agency/submit-oep-doc/`, data);
};
 

export const AgencySubmitIata = async(data) =>{
    return await axios.post(`${base_url}/agency/submit-iata-doc/`, data);
};
 

export const AgencySubmitHajj = async(data) =>{
    return await axios.post(`${base_url}/agency/submit-hajj-doc/`, data);
};
 

export const AgencySubmitPato = async(data) =>{
    return await axios.post(`${base_url}/agency/submit-pato-doc/`, data);
};
 

export const AgencySubmitCNIC = async(data) =>{
    return await axios.post(`${base_url}/agency/submit-ceo-cnic/`, data);
};
 

export const AgencyGetDashboards = async(aid) =>{
    return await axios.get(`${base_url}/agency/dashboard/?agency_id=${aid}`);
};

export const AgencyGetDashboardCounts = async(aid) =>{
    return await axios.get(`${base_url}/agency/dashboard-counts/?agency_id=${aid}`);
};




// TOURS
export const AgencyGetTours = async(aid) =>{
    return await axios.get(`${base_url}/agency/tours/?agency_id=${aid}`);
};
export const AgencyGetToursId = async(aid) =>{
    return await axios.get(`${base_url}/agency/tours/${aid}/`);
};
export const AgencyCreateTour = async(data) =>{
    return await axios.post(`${base_url}/agency/tours/`, data);
};
export const AgencyDeleteTour = async(id) =>{
    return await axios.delete (`${base_url}/agency/tours/${id}/`);
};


// HAJJS
export const AgencyGetHajjs = async(aid) =>{
    return await axios.get(`${base_url}/agency/hajjs/?agency_id=${aid}`);
};
export const AgencyGetHajjsId = async(aid) =>{
    return await axios.get(`${base_url}/agency/hajjs/${aid}/`);
};
export const AgencyCreateHajj = async(data) =>{
    return await axios.post(`${base_url}/agency/hajjs/`, data);
};
export const AgencyDeleteHajj = async(id) =>{
    return await axios.delete (`${base_url}/agency/hajjs/${id}/`);
};

// TICKETS
export const AgencyGetTickets = async(aid) =>{
    return await axios.get(`${base_url}/agency/tickets/?agency_id=${aid}`);
};
export const AgencyGetTicketsId = async(aid) =>{
    return await axios.get(`${base_url}/agency/tickets/${aid}/`);
};
export const AgencyCreateTicket = async(data) =>{
    return await axios.post(`${base_url}/agency/tickets/`, data);
};
export const AgencyDeleteTicket = async(id) =>{
    return await axios.delete (`${base_url}/agency/tickets/${id}/`);
};

// VISA
export const AgencyGetVisas = async(aid) =>{
    return await axios.get(`${base_url}/agency/visas/?agency_id=${aid}`);
};
export const AgencyGetVisasId = async(aid) =>{
    return await axios.get(`${base_url}/agency/visas/${aid}/`);
};
export const AgencyCreateVisa = async(data) =>{
    return await axios.post(`${base_url}/agency/visas/`, data);
};
export const AgencyDeleteVisa = async(id) =>{
    return await axios.delete (`${base_url}/agency/visas/${id}/`);
};


// USERS
export const AgencyGetUsers = async(aid) =>{
    return await axios.get(`${base_url}/agency/users/?agency_id=${aid}`);
};

export const AgencyGetUserPermissions = async(uid) =>{
    return await axios.get(`${base_url}/agency/user-permissions/?uid=${uid}`);
};
export const AgencyUpdateUserPermissions = async(data) =>{
    return await axios.post(`${base_url}/agency/user-permissions/`, data);
};

export const AgencyRegisterUser = async(data) =>{
    return await axios.post(`${base_url}/agency/register-user/`, data);
};



// HOTELS
export const AgencyGetHotels = async(aid) =>{
    return await axios.get(`${base_url}/agency/hotels/?agency_id=${aid}`);
};
export const AgencyCreateHotel = async(data) =>{
    return await axios.post(`${base_url}/agency/hotels/`, data);
};
export const AgencyDeleteHotel = async(id) =>{
    return await axios.delete (`${base_url}/agency/hotels/${id}/`);
};



// ROOMS
export const AgencyGetRooms = async(hid) =>{
    return await axios.get(`${base_url}/agency/rooms/?hotel_id=${hid}`);
};
export const AgencyCreateRoom = async(data) =>{
    return await axios.post(`${base_url}/agency/rooms/`, data);
};
export const AgencyDeleteRoom = async(id) =>{
    return await axios.delete (`${base_url}/agency/rooms/${id}/`);
};



// BOOKINGS
export const AgencyGetTransportBookings = async(aid) =>{
    return await axios.get(`${base_url}/agency/transport-bookings/?agency_id=${aid}`);
};

export const AgencyGetRoomBookings = async(aid) =>{
    return await axios.get(`${base_url}/agency/room-bookings/?agency_id=${aid}`);
};

export const AgencyGetTourBookings = async(aid) =>{
    return await axios.get(`${base_url}/agency/tour-bookings/?agency_id=${aid}`);
};

export const AgencyGetHajjBookings = async(aid) =>{
    return await axios.get(`${base_url}/agency/hajj-bookings/?agency_id=${aid}`);
};

export const AgencyGetTicketBookings = async(aid) =>{
    return await axios.get(`${base_url}/agency/ticket-bookings/?agency_id=${aid}`);
};

export const AgencyGetVisaBookings = async(aid) =>{
    return await axios.get(`${base_url}/agency/visa-bookings/?agency_id=${aid}`);
};

export const AgencyUpdateTourBookings = async(data) =>{
    return await axios.post(`${base_url}/agency/tour-bookings/`, data);
};

export const AgencyUpdateVisaBookings = async(data) =>{
    return await axios.post(`${base_url}/agency/visa-bookings/`, data);
};

export const AgencyUpdateHajjBookings = async(data) =>{
    return await axios.post(`${base_url}/agency/hajj-bookings/`, data);
};

export const AgencyUpdateTicketBookings = async(data) =>{
    return await axios.post(`${base_url}/agency/ticket-bookings/`, data);
};

export const AgencyUpdateTransportBookings = async(data) =>{
    return await axios.post(`${base_url}/agency/transport-bookings/`, data);
};

export const AgencyUpdateRoomBookings = async(data) =>{
    return await axios.post(`${base_url}/agency/room-bookings/`, data);
};


// TRANSPORTS
export const AgencyGetTransports = async(aid) =>{
    return await axios.get(`${base_url}/agency/transports/?agency_id=${aid}`);
};
export const AgencyGetTransportsId = async(aid) =>{
    return await axios.get(`${base_url}/agency/transports/${aid}/`);
};
export const AgencyCreateTransport = async(data) =>{
    return await axios.post(`${base_url}/agency/transports/`, data);
};
export const AgencyDeleteTransport = async(id) =>{
    return await axios.delete (`${base_url}/agency/transports/${id}/`);
};






// ADDONS
export const AgencyGetAddons = async(aid, type) =>{
    return await axios.get(`${base_url}/agency/addons/?agency_id=${aid}&type=${type ? type : 'all'}`);
};
export const AgencyCreateAddon = async(data) =>{
    return await axios.post(`${base_url}/agency/addons/`, data);
};
export const AgencyDeleteAddon = async(id) =>{
    return await axios.delete (`${base_url}/agency/addons/${id}/`);
};



// SELECTORS
export const AgencyGetTypes = async(type) =>{
    return await axios.get(`${base_url}/agency/types/?type=${type}`);
};

export const AgencyGetFeatures = async(type) =>{
    return await axios.get(`${base_url}/agency/features/?type=${type}`);
};


export const AgencyGetTourTransports = async(aid) =>{
    return await axios.get(`${base_url}/agency/tour-transports/?agency_id=${aid}`);
};


export const AgencyGetMargins = async(aid) =>{
    return await axios.get(`${base_url}/agency/margins/?agency_id=${aid}`);
};



