import { createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { AgencyGetDashboardCounts } from "../apis/agency";

const initialState = {
  uname: "",
  name: "",
  email: "",
  profile: "",
  isLogged: false,
  uid: 0,
  agencyName: "",
  loggedate: "",
  agencyID: 0,
  isSuperUser: false,
};

export const userSlice = createSlice({
  name: "CMSAgencyUser",
  initialState,
  reducers: {
    setName: (state, action) => {
      state.name = action.payload;
    },
    setUName: (state, action) => {
      state.uname = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    setIsLogged: (state, action) => {
      state.isLogged = action.payload;
    },
    setloggedate: (state, action) => {
      state.loggedate = action.payload;
    },
    setUid: (state, action) => {
      state.uid = action.payload;
    },
    setAgencyName: (state, action) => {
      state.agencyName = action.payload;
    },
    setAgencyID: (state, action) => {
      state.agencyID = action.payload;
    },
    setIsSuperUser: (state, action) => {
      state.isSuperUser = action.payload;
    },

  },
  extraReducers: {
    // Extra reducer comes here
  },
});

export const searchSlice = createSlice({
  name: "search",
  initialState: {
    svalue: "",
    country: "",
  },
  reducers: {
    setValue: (state, action) => {
      state.svalue = action.payload;
    },
    setCountry: (state, action) => {
      state.country = action.payload;
    },
  },
});

// export const userEducation = createSlice({
//   name:"userEducation"
// })

export const { setName, setUName, setEmail, setProfile, setIsLogged, setloggedate, setUid, setAgencyName, setIsSuperUser, setAgencyID } = userSlice.actions;
export const { setValue, setCountry } = searchSlice.actions;



export const sideCountsSlice = createSlice({
  name: "CMSAgencySideCounts",
  initialState: {
    tours: 0,
    hajjs: 0,
    tickets: 0,
    transports: 0,
    users: 0,
    visas: 0,
    rooms: 0,
    bookings: 0
  },
  reducers: {
    setTours: (state, action) => {
      state.tours = action.payload;
    },
    setHajjs: (state, action) => {
      state.hajjs = action.payload;
    },
    setTickets: (state, action) => {
      state.tickets = action.payload;
    },
    setTransports: (state, action) => {
      state.transports = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setVisas: (state, action) => {
      state.visas = action.payload;
    },
    setRooms: (state, action) => {
      state.rooms = action.payload;
    },
    setBookings: (state, action) => {
      state.bookings = action.payload;
    },
  },
  extraReducers: {
    // Extra reducer comes here
  },
});

export const { setTours, setTransports, setUsers, setVisas, setRooms, setBookings, setHajjs, setTickets } = sideCountsSlice.actions;

export const updateSidebarCounts = (agencyID) => async (dispatch) => {
  AgencyGetDashboardCounts(agencyID ? agencyID : 0).then(response => {
    if (response.status === 200) {
      dispatch(setRooms(response.data.data.rooms))
      dispatch(setTours(response.data.data.tours))

      dispatch(setHajjs(response.data.data.hajjs))
      dispatch(setTickets(response.data.data.tickets))

      dispatch(setVisas(response.data.data.visas))
      dispatch(setTransports(response.data.data.transports))
      dispatch(setUsers(response.data.data.users))
      dispatch(setBookings(response.data.data.bookings))
    }
  }).catch(err => {
    console.log('eeeeeeeee', err.message);
    // setLoadingData(false);
  });
};





export const userPermissionsSlice = createSlice({
  name: "CMSAgencyUserPermissions",
  initialState: {
    userPermissions: {
      DashboardView: false,
      BookingsView: true,
      BookingsConfirm: false,
      BookingsCancel: false,
      ToursView: true,
      ToursCreate: false,
      ToursUpdate: false,
      ToursDelete: false,
      HajjView: true,
      HajjCreate: false,
      HajjUpdate: false,
      HajjDelete: false,
      VisasView: true,
      VisasCreate: false,
      VisasUpdate: false,
      VisasDelete: false,
      TicketsView: true,
      TicketsCreate: false,
      TicketsUpdate: false,
      TicketsDelete: false,
      HotelsView: true,
      HotelsCreate: false,
      HotelsUpdate: false,
      HotelsDelete: false,
      TransportsView: true,
      TransportsCreate: false,
      TransportsUpdate: false,
      TransportsDelete: false,
      UsersView: true,
      UsersCreate: false,
      UsersUpdate: false,
      UsersDelete: false
    }
  },
  reducers: {
    setPermissions: (state, action) => {
      state.userPermissions = action.payload;
    },
  },
  extraReducers: {
    // Extra reducer comes here
  },
});

export const { setPermissions } = userPermissionsSlice.actions;
