export const base_url = "https://api.cmstravel.pk";
// export const base_url = "http://localhost:8000";




export const altBannerImage = '/media/src/alt-banner-image.jpg';






export const CKModules = {
    toolbar: [
        // [{ 'font': [] }, { 'size': ['small', 'normal', 'large', 'huge'] }],
        // [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        // [{ 'header': '1' }, { 'header': '2' }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['bold', 'italic', 'underline'],
        // [{ 'align': [] }],
        ['link'],
        // ['image']
    ]
};

